import styled from 'styled-components';

import theme from 'styles/theme';
import StyledButton from 'components/Button';

export const Form = styled.form`
  margin-top: 40px;
  border: 1px solid ${theme.color.paper2};
  margin-bottom: 2px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;

  @media (max-width: ${theme.size.tablet}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Input = styled.input`
  border: none;
  padding: 12px 15px;
  flex: 1;
  line-height: 1.3;
  border-radius: ${theme.radius};
  margin-right: 8px;
  background-color: ${theme.color.paper2};
  color: ${theme.color.gray};

  @media (max-width: ${theme.size.tablet}) {
    width: 100%;
    margin: 0 0 5px;
  }

  &:focus {
    outline: none;
    color: ${theme.color.ink};
    background-color: ${theme.color.paper};
  }
`;

export const Button = styled(StyledButton)`
  @media (max-width: ${theme.size.tablet}) {
    margin-top: 5px;
  }
`;

export const Message = styled.div``;
