import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { Location } from '@reach/router';
import { FirebaseContext } from 'gatsby-plugin-firebase';

import Layout from 'components/Layout';
import { UserContext } from 'utils/firebase';
import Loader from 'components/Loader';
import { Grid, Container } from 'components/Grid';
import Button from 'components/Button';
import { H5 } from 'components/Text';

import RegisterForm from './RegisterForm';
import { Loading, UserList, User, Inner, Name, Email } from './styles';

export const AdminPageTemplate = ({ page, location }) => {
  const firebase = React.useContext(FirebaseContext);
  const firebaseUserContext = useContext(UserContext);
  const [users, setUsers] = React.useState();

  // useFirebase(firebase => {
  //   firebase
  //     .database()
  //     .ref("/users")
  //     .orderByChild("emailAddress")
  //     .once("value", snap => setUsers(snap.val()));
  // }, []);

  useEffect(
    () => {
      if (!firebase) {
        return;
      }

      firebase
        .database()
        .ref('/users')
        .orderByChild('emailAddress')
        .once('value', snap => setUsers(snap.val()));
    },
    [firebase]
  );

  const deactivateUser = uid => {
    firebase
      .database()
      .ref(`users/${uid}`)
      .update({
        isActive: false
      });

    window.location.reload();
  };

  const activateUser = uid => {
    firebase
      .database()
      .ref(`users/${uid}`)
      .update({
        isActive: true
      });

    window.location.reload();
  };

  const setRole = (uid, role) => {
    firebase
      .database()
      .ref(`users/${uid}`)
      .update({
        role
      });

    window.location.reload();
  };

  if (
    firebaseUserContext.readyToFetch &&
    !firebaseUserContext.refUser &&
    !firebaseUserContext.user
  ) {
    navigate('/');
    return null;
  }

  if (
    firebaseUserContext.refUser &&
    firebaseUserContext.refUser.role !== 'admin'
  ) {
    navigate('/');
    return null;
  }

  if (!users) {
    return (
      <Loading>
        <Loader size="80px" />
      </Loading>
    );
  }

  var usersArray = Object.entries(users);

  return (
    <>
      <Grid>
        <Container>
          <RegisterForm />
          <UserList>
            <H5>{usersArray.length} Users</H5>
            {usersArray.map((data, index) => {
              const user = data[1];
              const uid = data[0];
              const { role, isActive } = user;
              return (
                <User key={index} isActive={user.isActive}>
                  <Inner>
                    <Name>{user.displayName}</Name>
                    <Email>{user.email}</Email>
                  </Inner>
                  <Button
                    mx="10px"
                    size="small"
                    opacity={role === 'admin' ? '1' : '0.3'}
                    onClick={() =>
                      setRole(uid, role === 'admin' ? 'user' : 'admin')
                    }
                  >
                    Admin
                  </Button>
                  {isActive ? (
                    <Button size="small" onClick={() => deactivateUser(uid)}>
                      Deactivate
                    </Button>
                  ) : (
                    <Button size="small" onClick={() => activateUser(uid)}>
                      Activate
                    </Button>
                  )}
                </User>
              );
            })}
          </UserList>
        </Container>
      </Grid>
    </>
  );
};

AdminPageTemplate.propTypes = {
  title: PropTypes.string
};

const AdminPage = ({ data, form }) => {
  const { frontmatter: page } = data.markdownRemark;

  return (
    <Layout content={page} shade="lab">
      <Location>
        {({ navigate, location }) => (
          <AdminPageTemplate page={page} location={location} />
        )}
      </Location>
    </Layout>
  );
};

export default AdminPage;

export const adminPageQuery = graphql`
  query AdminPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        page {
          title
        }
      }
    }
  }
`;
