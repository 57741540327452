import styled from 'styled-components';

import theme from 'styles/theme';
import { Text } from 'components/Text';

export const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: white;
`;

export const UserList = styled.ul`
  margin: 0;
  padding: 80px 0 100px;
  width: 100%;
`;

export const User = styled.li`
  background-color: ${theme.color.paper2};
  padding: 12px 15px;
  margin-bottom: 2px;
  width: 100%;
  display: flex;
  align-items: center;
  opacity: ${props => (props.isActive ? '1' : '0.6')};
`;

export const Inner = styled.div`
  flex: 1;
`;

export const Name = styled(Text)`
  font-weight: ${theme.font.weight.bold};
  margin-bottom: 3px;
  line-height: 1;
`;

export const Email = styled(Text)`
  color: ${theme.color.gray};
  line-height: 1;
  font-size: ${theme.font.size.zeta};
`;
